.MuiList-padding {
  padding: 4px !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #f4cccc !important;
}
.MuiMenuItem-root {
  border-radius: 8px !important;
  font-size: 12px !important;
  min-height: 32px;
}
.dField .MuiOutlinedInput-input {
  padding: 8px;
  font-size: 12px;
}
