main::-webkit-scrollbar {
  width: 10px;
  border-radius: 12px;
  background-color: #f4cccc;
  margin-top: 5px;
  margin-bottom: 5px;
}
main::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #d43434;
}
.comment::-webkit-scrollbar,
.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 12px;
  background-color: #f4cccc;
  margin-top: 5px;
  margin-bottom: 5px;
}
.comment::-webkit-scrollbar-thumb,
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #d43434;
}
