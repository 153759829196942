.dispatchFormBox p{
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000bd;
}
.custom-text-field input::placeholder {
    color: #000 !important; /* Set the desired color for the placeholder text */
  }
  .custom-text-field input:-ms-input-placeholder {
    color: #000 !important;
  }
 
  .custom-text-field input::-moz-placeholder {
    color: #000 !important;
  }
body{
    font-family: 'Bebas Neue', sans-serif;
    overflow-x: hidden;
}
.dispatchFormBox button{
    text-transform: capitalize;
}
.viewSlectionBox .viewSelectionBtn{
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    border: none;
    margin: 15px 10px 10px 0px;
    background: #fff;
    color: rgb(215 31 31 / 84%);
    box-shadow: none;
}
.viewSlectionBox .viewSelectionBtn:hover{
    background-color: #fff;
    box-shadow: none;
}
.viewSlectionBox{
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}
.boxWithCopy{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.fontText{
    text-transform: capitalize !important;
}
.widthHeadingPre{
    width: 93% !important;
}
@media (max-width: 600px){
    .tableDataBox{
        width: 100%;
overflow-x: auto;
    }
}
